.container {
    & div {
        width: 100%;
    }
}

.image {
    width: 25% !important;
    margin-right: auto;
    margin-left: auto;
}