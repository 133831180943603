@import "./variaveis";
@import "./breakpoints";

.container {
    padding: 30px $padding-horizontal;

    @media (max-width: $desktop_xsm) {
        padding: 30px $padding-horizontal-mobile;
    }
    @media (min-width: $desktop_xsm) and (max-width: $desktop_sm) {
        padding: 30px $padding-horizontal-sm;
    }
    @media (min-width: $desktop_sm) and (max-width: $desktop_md) {
        padding: 30px $padding-horizontal-md;
    }
}

.titulo {
    font-size: 3rem;
    margin-bottom: 30px;
}