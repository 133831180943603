.div {
    border: 1px solid #cecece;
    border-radius: 10px;
    padding: 1rem;
}

.accordion{
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(207 216 220 / var(--tw-border-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
}