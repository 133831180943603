.navbar {
    position: sticky;
    top: 0;
    z-index: 10;
    height: max-content;
    max-width: 100% !important;
    border-radius: 0;
    padding: 0.5rem 1rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 960px) {
        .lg\:px-8 {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        .lg\:py-4 {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

    &__div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        --tw-text-opacity: 1;
        color: rgb(38 50 56 / var(--tw-text-opacity));

        &__button {
            border-radius: 100px !important;
            padding: 15px 15px !important;
        }

        &__menu {
            &__menu__handler {
                &__avatar{
                    cursor: pointer;
                    margin-left: 1rem;
                }
            }
        }
    }
}