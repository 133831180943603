@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";
@import "react-toastify/dist/ReactToastify.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;

}

body {
    /*background-color: #F6F6F6;*/
}

#root {
    flex: 1;
    /*background: #f7f7f7;*/
}

#root > main.container_principal {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root > main div.relative.w-full {
    min-width: initial !important;
}

#root > main button {
    position: initial !important;
    overflow: initial !important;
}

.header-bold {
    font-weight: bold;
}

#root > main .Toastify__toast-container {
    z-index: 10000 !important;
}

#root > main .multiline-cell {
    white-space: pre-line;
}