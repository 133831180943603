.div {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__imagem {
        width: 30%;
        border-radius: 100px;
        margin-right: auto;
        margin-left: auto;
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}