@import "styles/variaveis";
@import "styles/breakpoints";

@import "bootstrap"; // Importar o SCSS do Bootstrap

.container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    min-height: 100vh;
    gap: 1rem;

    &__card {
        align-items: center;

        @media(max-width: $desktop_xsm) {
            width: 100% !important;

            &__body {
                margin-top: -4rem;
            }
        }

        @media(min-width: $desktop_xsm) and (max-width: $desktop_sm) {
            width: 80% !important;

            &__body {
                margin-top: -4rem;
            }
        }

        @media(min-width: $desktop_sm) and (max-width: $desktop_md) {
            width: 60% !important;

            &__body {
                margin-top: -4rem;
            }
        }

        @media(min-width: $desktop_md) {
            width: 100% !important;

            &__body {
                margin-top: -5rem;
            }
        }

        &__imagem {
            width: 30%;
            border-radius: 100px;
            margin: 20px auto 0 auto;
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            width: 100%;
            position: relative;
        }
    }

    &__imagem__profile {
        height: 17rem;
        width: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        object-position: center;
    }
}