@import "styles/variaveis";
@import "styles/breakpoints";

@import "bootstrap"; // Importar o SCSS do Bootstrap

.container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    min-height: 100vh;

    &__card {
        padding: $padding-horizontal;
        align-items: center;

        @media(max-width: $desktop_xsm) {
            width: 75% !important;
        }

        @media(min-width: $desktop_xsm) and (max-width: $desktop_sm) {
            width: 75% !important;
        }

        @media(min-width: $desktop_sm) and (max-width: $desktop_md) {
            width: 45% !important;
        }

        @media(min-width: $desktop_md) {
            width: 40% !important;
        }

        &__imagem {
            width: 30%;
            border-radius: 100px;
            margin: 20px auto 0 auto;
        }

        &__body {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            padding: 0 $padding-horizontal !important;
            width: 100%;
        }
    }
}
