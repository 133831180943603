@import "styles/variaveis";
@import "styles/breakpoints";

.loading {
    padding: 30px $padding-horizontal;
    @media (max-width: $desktop_xsm) {
        padding: 30px $padding-horizontal-mobile;
    }
    @media (min-width: $desktop_xsm) and (max-width: $desktop_sm) {
        padding: 30px $padding-horizontal-sm;
    }
    @media (min-width: $desktop_sm) and (max-width: $desktop_md) {
        padding: 30px $padding-horizontal-md;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000!important;
}